// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.access-option-content__w55l{padding:0 8px;margin:0}html.app__layout--desktop .access-option-content__w55l{padding:0 60px 0 12px}.access-option-content__w55l [class^=desktop]{display:none}.access-option-content__loader_XzKLw{position:relative;margin-top:20px}.access-option-content__inner_tVomt{padding:0}.access-option-content__personal-link_IpXqz{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;position:relative;display:flex;align-items:center;height:44px;padding:0 60px 0 12px;margin:0 8px 20px;overflow:hidden;background-color:var(--Layer1);border-radius:4px}.access-option-content__personal-link-copy_quDTT{position:absolute;top:0;right:0;border-radius:0}.access-option-content__personal-link-copy_quDTT.button--icon-only_w8XO4{width:52px}.access-option-content__personal-link-bookmark_ZCkmq{display:none;position:relative;z-index:5;display:block;width:107px;margin:-36px 0 0}html.app__layout--desktop .access-option-content__w55l{padding:16px 24px 32px}html.app__layout--desktop .access-option-content__w55l [class^=desktop]{display:block}html.app__layout--desktop .access-option-content__w55l [class^=mobile]{display:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"access-option-content": `access-option-content__w55l`,
	"access-option-content__loader": `access-option-content__loader_XzKLw`,
	"access-option-content__inner": `access-option-content__inner_tVomt`,
	"access-option-content__personal-link": `access-option-content__personal-link_IpXqz`,
	"access-option-content__personal-link-copy": `access-option-content__personal-link-copy_quDTT`,
	"button--icon-only": `button--icon-only_w8XO4`,
	"access-option-content__personal-link-bookmark": `access-option-content__personal-link-bookmark_ZCkmq`
};
export default ___CSS_LOADER_EXPORT___;
