// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.access-options-list_cqjIq{padding:0 8px}html.app__layout--desktop .access-options-list_cqjIq{padding:16px 24px 32px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"access-options-list": `access-options-list_cqjIq`
};
export default ___CSS_LOADER_EXPORT___;
